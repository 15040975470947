import React from "react"
import styled from 'styled-components'
import { color, device } from '../../layout/global'
import { Link } from "gatsby"
import Arrow from '../../images/Products/right-arrow.svg'

const Lang = ({lang, location}) => {
  let page = location.pathname.substr(3)
    
  return (
    <Wrapper>
        <button className="dropbtn">{lang} <Arrow/></button>
        <div className="dropdown-content">
          {lang === "PT" &&
            <>
              <Link className='nav-link' partiallyActive activeClassName="active" to={`/en${page}`}>EN</Link>
              <Link className='nav-link' partiallyActive activeClassName="active" to={`/ko${page}`}>KO</Link>
            </>
          }
          {lang === "EN" &&
            <>
              <Link className='nav-link' partiallyActive activeClassName="active" to={`/pt${page}`}>PT</Link>
              <Link className='nav-link' partiallyActive activeClassName="active" to={`/ko${page}`}>KO</Link>
            </>
          }
          {lang === "KO" &&
            <>
              <Link className='nav-link' partiallyActive activeClassName="active" to={`/pt${page}`}>PT</Link>
              <Link className='nav-link' partiallyActive activeClassName="active" to={`/en${page}`}>EN</Link>
            </>
          }          
        </div>
    </Wrapper>
  )
}

export default Lang

const Wrapper = styled.section`
  position: relative;
  display:flex;
  align-items:center;
  cursor:pointer;
  margin-left: 2.5rem;
  z-index:3;

  .dropbtn {
    color:${color.white};
    font-size: 1.2rem;
    text-transform: uppercase;
    background:none;
    outline: none;
    border: none;
    padding:0;
    cursor: pointer;
    display:flex;
    align-items:center;
    margin-right:5px;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    color:${color.white};
    top: 12px;
    
    @media ${device.tabletP}{
      margin-left:0;
    }
  }

  .dropdown-content a {
    color: ${color.greyOpacity};
    display: block;
    margin:13px 0;
    height:0;
  }

  &:hover .dropdown-content {
    display:flex;
    flex-direction:column;
  }

  .nav-link:hover{
    color:#fff;
  }

  svg{
    transform: rotate(90deg);
    fill:#fff;
    width:5px;
    margin-left:5px;
  }
`