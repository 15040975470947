import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({
  title,
  titleTemplate,
  description,
  img,
  imageWidth,
  imageHeight,
  pathname,
  article,
  lang,
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          defaultTitleTemplate,
          defaultDescription,
          siteUrl,
          defaultImg,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        titleTemplate: titleTemplate || defaultTitleTemplate,
        description: description || defaultDescription,
        img: `${siteUrl}${img || defaultImg}`,
        imageWidth: imageWidth,
        imageHeight: imageHeight,
        url: `${siteUrl}${pathname || ""}`,
      }
      return (
        <>
          <Helmet
            htmlAttributes={{ lang }}
            title={seo.title}
            titleTemplate={seo.titleTemplate}
            defer={false}
          >
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.img} />
            {seo.url && <meta property="og:url" content={`${seo.url}`} />}
            {article ? (
              <meta property="og:type" content="article" />
            ) : (
              <meta property="og:type" content="website" />
            )}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.img && <meta property="og:image" content={seo.img} />}
            {seo.imageWidth && (
              <meta property="og:image:width" content={seo.imageWidth} />
            )}
            {seo.imageHeight && (
              <meta property="og:image:height" content={seo.imageHeight} />
            )}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.img && <meta name="twitter:image" content={seo.img} />}
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  titleTemplate: PropTypes.string,
  description: PropTypes.string,
  img: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  lang: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  titleTemplate: "%s | Ramalhos - Pure Baking Pleasure",
  description: null,
  img: null,
  pathname: null,
  article: false,
  lang: `en`,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultTitleTemplate: titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImg: img
      }
    }
  }
`
