import React from 'react'
import styled from 'styled-components'
import { font, color } from "../../layout/global"

const Component = ({ id, info, ko }) => {
  return (
    <Wrapper id={id} ko={ko}>
      <a href="https://ramalhos.com/en" target="_blank" rel="noreferrer noopener">
        <div className="text-container">
          <h1>{info.title}</h1>
          <h2>{info.link}</h2>
        </div>
      </a>
    </Wrapper>
  )
}

export default Component

const Wrapper = styled.section`
  height: 350px;
  width: 100%;
  overflow: hidden;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  .text-container {
    text-align: center;
    line-height: 1;
    cursor: pointer;

    &:hover > h2 { color: ${color.red} }
  }
  .text-container h1 {
    ${props => props.ko ? font.Noto400 : font.financierLight};
    color: ${color.greyDarker};
    font-size: 4rem;
  }
  .text-container h2 {
    ${props => props.ko ? font.Noto700 : font.robotoBold};
    color: ${color.white};
    font-size: 5rem;
    transition: all 0.3s ease-out;
  }

  @media screen and (min-width: 320px) and (max-width: 1920px) {
    .text-container h1 {
      font-size: calc(20px + 20 * (100vw - 320px) / 1600)
    }
    .text-container h2 {
      font-size: calc(30px + 20 * (100vw - 320px) / 1600)
    }
  }
`