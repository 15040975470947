import React, { useState, useEffect, useRef, createRef } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Slider from "react-slick"
import { font } from "../../layout/global"

import LeftArrow from "../../images/Products/left-arrow.svg"
import RightArrow from "../../images/Products/right-arrow.svg"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const Component = ({ id, className, info }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const ref = useRef(null),
    prevArrowRef = useRef(null),
    nextArrowRef = useRef(null),
    paginationRef = useRef(null)
  const slideRefs = useRef(info.map(() => createRef()))

  function NextArrow(props) {
    const { onClick, style } = props

    return (
      <>
        <div
          onClick={onClick}
          onKeyUp={onClick}
          role="button"
          tabIndex={0}
          className="next-arrow"
          style={{
            ...style,
            display: "block",
            width: "15px",
            position: "absolute",
            zIndex: "1",
            bottom: "80px",
            right: "35px",
            outline: "0",
          }}
          ref={nextArrowRef}
        >
          <RightArrow />
        </div>
      </>
    )
  }

  function PrevArrow(props) {
    const { onClick, style } = props

    return (
      <>
        <div
          onClick={onClick}
          onKeyUp={onClick}
          role="button"
          tabIndex={0}
          className="prev-arrow"
          style={{
            ...style,
            display: "block",
            width: "15px",
            position: "absolute",
            zIndex: "1",
            bottom: "80px",
            right: "180px",
            outline: "0",
          }}
          ref={prevArrowRef}
        >
          <LeftArrow />
        </div>
      </>
    )
  }

  const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    autoplay: false,
    centerPadding: "350px",
    slidesToShow: 1,
    dots: true,
    dotsClass: "pagination",
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerMode: true,
          centerPadding: "150px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1921,
        settings: {
          centerMode: true,
          centerPadding: "350px",
          slidesToShow: 1,
        },
      },
    ],
    customPaging: function(i) {
      return (
        <h1 ref={paginationRef}>
          0{i + 1} / <span>0{info.length}</span>
        </h1>
      )
    },
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setActiveSlide(next),
  }

  useEffect(() => {
    if (activeSlide === 0) {
      prevArrowRef.current.style.opacity = 0
      nextArrowRef.current.style.opacity = 1
    } else if (activeSlide === info.length - 1) {
      prevArrowRef.current.style.opacity = 1
      nextArrowRef.current.style.opacity = 0
    } else {
      prevArrowRef.current.style.opacity = 1
      nextArrowRef.current.style.opacity = 1
    }
  }, [activeSlide, info.length])

  useEffect(() => {
    let tl = gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: ref.current,
        start: "40% center",
      },
    })
    slideRefs.current.map(i =>
      tl.fromTo(
        i.current.parentElement,
        { opacity: 0, y: 10 },
        { opacity: 1, y: 0, duration: 1.5, ease: "power4.out" },
        0
      )
    )
    tl.fromTo(
      paginationRef.current,
      { opacity: 0, y: 10 },
      { opacity: 1, y: 0, duration: 0.5, ease: "power4.out" },
      "<+0.5"
    )
  }, [])

  return (
    <Wrapper ref={ref} id={id} className={className}>
      <Slider {...settings}>
        {info.map((i, index) => (
          <div className="container" key={index} ref={slideRefs.current[index]}>
            <div className="image">
              {i.image && (
                <Img
                  fluid={i.image.childImageSharp.fluid}
                  alt={i.image.name}
                  imgStyle={{
                    objectFit: "contain",
                    objectPosition: "center center",
                  }}
                />
              )}
            </div>
          </div>
        ))}
      </Slider>
    </Wrapper>
  )
}

export default Component

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  .slick-track {
    display: flex;
  }

  .slick-slider {
    margin: 250px 0 150px 0;
  }

  .slick-slide {
    margin: 0px 25px;
    transition: all 0.3s ease-out;
  }

  .slick-slide:not(.slick-active) {
    opacity: 0.3;
  }

  svg {
    fill: #fff;
    transition: all 0.3s ease-out;

    &:hover {
      fill: red;
      cursor: pointer;
    }
  }

  .pagination {
    position: absolute;
    right: 60px;
    bottom: 75px;

    li {
      display: none;
      pointer-events: none;
    }

    .slick-active {
      display: block;

      h1 {
        ${font.roboto400};
        color: #fff;
        font-size: 4rem;
        line-height: 0.9;
        letter-spacing: -1px;
      }

      span {
        font-size: 3rem;
      }
    }
  }

  button {
    z-index: 2;
  }

  .prev-arrow,
  .next-arrow {
    transition: all 0.3s ease-out;
  }

  image {
    pointer-events: none;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .slick-slider {
      margin: 150px 0 150px 0;
    }
  }

  @media only screen and (min-width: 1921px) {
    .slick-slider {
      margin: 350px 0 350px 0;
    }
  }
`
