import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { font, color, device } from "../../layout/global"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const FeatureLine = ({
  id,
  info,
  titleWidth,
  textWidth,
  marginLeft,
  positionCenter,
  whiteBg,
  alignLeft,
  ko,
}) => {
  const ref = useRef(null),
    imageRef = useRef(null),
    questionRef = useRef(null),
    titleRef = useRef(null),
    textRef = useRef(null),
    containerRef = useRef(null)

  // ANIMATIONS
  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.matchMedia(device.tabletP).matches
    ) {
      gsap.fromTo(
        questionRef.current,
        { opacity: 0, y: 10 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power4.out",
          scrollTrigger: {
            trigger: ref.current,
            start: "30% 80%",
          },
        }
      )

      gsap
        .timeline({
          paused: true,
          scrollTrigger: {
            trigger: ref.current,
            start: "15% 70%",
          },
        })
        .fromTo(
          questionRef.current,
          { fontSize: "4rem" },
          { fontSize: "2rem", duration: 1, ease: "power4.out" }
        )
        .fromTo(
          containerRef.current,
          { opacity: 0, scaleY: 0.5 },
          { opacity: 1, scaleY: 1, duration: 0.5, ease: "power2.out" },
          ">-1"
        )
        .fromTo(
          [titleRef.current, textRef.current],
          { opacity: 0, y: 5 },
          {
            opacity: 1,
            y: 0,
            duration: 0.5,
            stagger: 0.25,
            ease: "power4.out",
          },
          ">"
        )
        .fromTo(
          imageRef.current,
          { x: "50vw" },
          { x: "0vw", duration: 1.5, ease: "power4.out" },
          "<"
        )
    }
  }, [])
  // ---

  return (
    <Wrapper
      titleWidth={titleWidth}
      textWidth={textWidth}
      marginLeft={marginLeft}
      positionCenter={positionCenter}
      id={id}
      ref={ref}
      alignLeft={alignLeft}
      ko={ko}
    >
      {info.image && (
        <div className="image" ref={imageRef}>
          <Img
            fluid={info.image.childImageSharp.fluid}
            alt={info.image.name}
            imgStyle={{ objectFit: "contain", objectPosition: "center center" }}
          />
        </div>
      )}
      <div className="question" id="question">
        <h1 ref={questionRef}>{info.question}</h1>
      </div>
      <div className="container" ref={containerRef}>
        <h1
          ref={titleRef}
          dangerouslySetInnerHTML={{ __html: info.title }}
        ></h1>
        <p ref={textRef} dangerouslySetInnerHTML={{ __html: info.text }}></p>
      </div>
    </Wrapper>
  )
}

export default FeatureLine

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  overflow: hidden;

  @media ${device.tabletP} {
    margin-top: 60px;
  }

  @media ${device.desktop} {
    margin-top: unset;
    display: grid;
    height: 100vh;
    background: ${color.white};
  }

  @media ${device.desktop} and (max-width: 1440px) {
    margin-top: -5%;
  }

  h1 {
    width: ${props => (props.titleWidth ? props.titleWidth : "100%")};
    margin: ${props => props.positionCenter && "0 auto"};
    text-align: ${props => (props.positionCenter ? "center" : "left")};
    position: relative;
    ${props => (props.ko ? font.Noto700 : font.robotoBold)};
    font-size: ${props => (props.ko ? "5rem" : "6rem")};
    color: ${props => (props.whiteBg ? color.greyDark : color.white)};
    line-height: 1;
    letter-spacing: 1px;

    & span {
      display: ${props => (props.ko ? "none" : "block")};
      color: ${color.red};
    }
  }

  p {
    width: ${props => (props.textWidth ? props.textWidth : "500px")};
    margin: ${props => (props.positionCenter ? "50px auto" : "50px 0 0 0")};
    text-align: ${props => (props.positionCenter ? "center" : "left")};
    ${props =>
      props.ko
        ? font.Noto700
        : props.whiteBg
        ? font.robotoMedium
        : font.roboto400};
    font-size: 2rem;
    color: ${props => (props.whiteBg ? "#97999b" : color.white)};
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  .question {
    background-color: ${color.white};
    display: flex;
    align-items: center;
    padding: 50px 0;

    @media ${device.desktop} {
      padding: 10vh 0 10vh 0;
    }
  }

  .question h1 {
    ${props => (props.ko ? font.Noto400 : font.robotoMedium)};
    color: ${color.greyDark};
    font-size: 2rem;
    padding: 0 25px;

    @media ${device.desktop} {
      padding: 0 20vw 0 20vw;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px 25px;
    background-color: black;
    transform-origin: bottom;

    @media ${device.desktop} {
      padding: 0 20vw 0 20vw;
      padding-bottom: 10vh;
    }
  }

  .image {
    position: absolute;
    bottom: 0%;
    right: 0%;
    width: 45vw;
    z-index: 3;

    @media ${device.desktop} {
      width: 37.5vw;
    }
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .question h1,
    .container {
      padding: 0 10vw 0 10vw;
    }

    .container h1 {
      font-size: ${props => props.ko && "3rem"};
    }
  }
`
