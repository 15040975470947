import styled from "styled-components"
import { font, color, ease } from "../../layout/global"

// STYLED
const Wrapper = styled.header`
  width: 100%;
  height: 125px;
  z-index: 2;
  position: fixed;
  opacity:1;
  transition: all 0.3s ${ease.out};

  .lang{
    display:flex;
    margin-left:2.5rem;

    .nav-link{
      margin-left:10px;
    }
    .nav-link.active {
      color: ${color.red};
    }
  }

  .header-wrapper {
    display: flex;
    align-items: center;
    margin: 0% 3%;
    height: 100%;
    ${props => props.ko ? font.Noto700 : font.robotoMedium};
  }

  .logo-container {
    svg {
      transition: all ${ease.out} 0.3s !important;
      fill: ${props => 
        props.isDarkened        ? color.greyDark : 
                                  color.white
      };
      height: 40px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    z-index: 3;
  }

  .nav-primary {
    display: flex;
    flex-direction: row;
    margin: 0 0 0 auto;
  }

  .dropbtn {
    color: ${props =>
      props.isDarkened        ? color.greyDark : 
                                color.white
    };

    svg{
      fill: ${props =>
        props.isDarkened        ? color.greyDark : 
                                  color.white
      };
    }
  }

  .nav-secondary {
    display: flex;
    flex-direction: row;
  }

  .opacity{
    opacity: 0 !important;
  }

  .display-none{
    display:none !important;
  }

  .nav-link{
    z-index: 3;
    background: none;
    border: none;
    outline: none;
  }

  .nav-link, .nav-secondary {
    display: flex;
    align-items: center;
    margin-left: 2.5rem;
    height: 6rem;
    color: ${props => (
      props.isDarkened  ? color.greyDark :  
                          color.white       
    )};
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: none;
    transition: color ${ease.out} 0.3s;
    pointer-events: all;
    transition: opacity 0.3s ease-in-out;
    transition-delay: 0.2s;

    &:hover {
      color: ${props => props.sidebarLinksColor && color.white};
    }
  }

  .hvr-overline-from-center {
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;

    &:before {
      content: "";
      position: absolute;
      z-index: 0;
      left: 51%;
      right: 51%;
      bottom: 12.5px;
      height: 3.5px;
      transition-property: left, right;
      transition-duration: 0.3s;
      transition-timing-function: ${ease.out};
      background: ${props => 
        props.sidebarLinksColor ? color.white :
          props.isDarkened ? color.greyDark : color.white
      };
    }

    &:hover:before {
      left: 25%;
      right: 25%;
    }
  }
  .hvr-overline-from-center.active {
    &:before {
      left: 25%;
      right: 25%;
    }
  }

  /* HIDE AND SHOW SCROLL */

  .header-wrapper{
    height:125px;
  }

  .headroom {
    will-change: transform;
    transition: transform 400ms linear;
    position:relative;
    /*backdrop-filter: blur(3px);*/
}

  .headroom--pinned {
      transform: translateY(0%);
  }

  .headroom--unpinned {
      transform: translateY(-100%);
  }

  /* --- */

  /* HAMBURGUER */

  .nav-secondary button{
    cursor: pointer;
    background-color: transparent;
    border: 0;
    z-index: 2;

    &:focus{
      outline: 0;
    }

    .hamburguer-icon {
      margin: 1em;
      width: 20px;

      &:hover:before, &:hover:after, &:hover div{
        background-color: ${props => props.sidebarLinksColor ? color.white: color.red};
      }
    }

    .hamburguer-icon:after, 
    .hamburguer-icon:before, 
    .hamburguer-icon div {
      background-color: ${props => (
      props.sidebarLinksColor ? color.white :
        props.location === '/assistencia' ? color.whiteOpacity :
          (props.isDarkened && props.location !== '/assistencia') ? 
            color.greyDark :  
            color.white       
      )};
      border-radius: 3px;
      content: '';
      display: block;
      height: 2px;
      margin: 4px 0;
      transition: all .2s ease-in-out;
    }

    .hamburguer-icon.active:before {
      transform: translateY(6px) rotate(135deg);
    }

    .hamburguer-icon.active:after {
      transform: translateY(-6px) rotate(-135deg);
    }

    .hamburguer-icon.active div {
      transform: scale(0);
    }
  }

  /* --- */

  #svg-logo-small {
    opacity: 0;
    pointer-events: none;
  }

`

export default Wrapper