import React, { useEffect, useRef } from 'react'
import styled from "styled-components"
import { font, color, device } from "../../layout/global"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const VideoDetail = ({ src, id, className, info, ko }) => {
  const breakpoints = useBreakpoint()

  const ref = useRef(null),
        videoRef = useRef(null),
        title1Ref = useRef(null),
        title2Ref = useRef(null),
        pRef = useRef(null)

  // ANIMATIONS
    useEffect(() => {
      if (typeof window !== 'undefined' && window.matchMedia(device.tabletL).matches) {
        let nata = document.querySelector(`#video-detail-pastel`)
        nata.pause()
        
        let tl = gsap.timeline({
          paused: true,
          scrollTrigger: {
            trigger: ref.current,
            start: '40% 70%'
          }
        })
        tl.fromTo(videoRef.current, {opacity: 0}, {opacity: 1, duration: 3, ease: 'power4.out'})
        tl.fromTo(title1Ref.current, {opacity: 0, x: -5}, {opacity: 1, x: 0, duration: 1.5, ease:'power4.out'}, '>-2')
        tl.fromTo(title2Ref.current, {opacity: 0, x: 5}, {opacity: 1, x: 0, duration: 1.5, ease:'power4.out'}, '<+0.5') 
        tl.add(function() {nata.play()}, '>-1')
        tl.fromTo(pRef.current, {opacity: 0, y: 5 }, {opacity: 1, y: 0, duration: 1.5, ease:'power4.out'}, '<')
        tl.fromTo(pRef.current, {color: `rgba(151, 153, 155, 1)`}, {color: `rgba(255, 255, 255, 1)`, duration: 2, ease:'power4.out'}, '>')
      }
    },[])
  // ---

  return (

    <Wrapper id={id} ref={ref} className={className} ko={ko}>
      <figure className="container" role="presentation">
        <video className="video" width="100%" autoPlay playsInline muted loop ref={videoRef} id="video-detail-pastel">
          <source src={src} type="video/mp4"></source>
        </video>

        <div className='text-container'>
          <h1 id="s-first-line" ref={title1Ref}>{info.title_1}</h1>
          <div id="s-second-line" ref={title2Ref}>
            <h1>{info.title_2}</h1>
            {!breakpoints.as && 
              <p ref={pRef} dangerouslySetInnerHTML={{ __html: info.text }}></p>
            }
          </div>
        </div>
      </figure>
    </Wrapper>
  )
}

export default VideoDetail

const Wrapper = styled.section`
  position: relative;
  width: 100vw;
  background-color: #000000; 
  min-height:100vh;
  
  @media ${device.mobileP} {
    min-height: 50vh;
  }
  @media ${device.tabletP}{
    height: 56.25vw;
    min-height: unset;
    max-height: 100vh;
    max-width: 177.78vh;
  }
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    height: 100vh;
  }


  figure{
    display: flex;
    justify-content: center;

    @media ${device.desktop}{
      display:unset;
    }
  }
  
  video {
    pointer-events: none;
    background-color: black;

    @media ${device.desktop}{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-60%);
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      height: 100%;
    }
  }

  .container {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .text-container {
    z-index: 1;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 70px 25px;

    @media ${device.tabletP}{
      padding: 70px 100px;
    }

    @media ${device.desktop}{
      padding: 70px 25px;
    }
  }

  h1 {
    ${props => props.ko ? font.Noto400 : font.financierLight};
    font-size: 4rem;
    letter-spacing: 1px;
    line-height: 1;
    color:#fff;
    text-align:left;

    @media ${device.mobileP} {
      font-size: ${props => props.ko ? '1.8rem' : '3rem'};
    }

    @media ${device.tabletP} {
      font-size: ${props => props.ko ? '2.5rem' : '4rem'};
    }

    @media ${device.desktop}{
      font-size: ${props => props.ko ? '5rem' : '6rem'};
    }

    @media only screen 
      and (min-device-width: 768px) 
      and (max-device-width: 1024px) 
      and (-webkit-min-device-pixel-ratio: 2) {
        font-size: ${props => props.ko ? '3rem' : '6rem'};
    }
  }

  #s-first-line {
    @media ${device.desktop}{
      position: absolute;
      top: ${props => props.ko ? '25%' : '28%'};
      left: ${props => props.ko ? '15%' : '22%'};
    }   
  }

  #s-second-line {
    @media ${device.desktop}{
      position: absolute;
      top: ${props => props.ko ? '60%' : '51%'};
      left: ${props => props.ko ? '55%' : '62%'};
    } 

    h1{
      text-align: right;

      @media ${device.desktop}{
        text-align:unset;
      }
    }
  }

  #s-second-line p {
    ${props => props.ko ? font.Noto700 : font.robotoMedium};
    color: ${color.grey};
    text-align: right;
    width: fit-content;
    margin: 0 0 0 auto;

    @media ${device.mobileP}{
      font-size:1.6rem;
    }

    @media ${device.desktop}{
      font-size: 2rem;
    }
  }

  .p-mobile {
    ${props => props.ko ? font.Noto700 : font.robotoMedium};
    color: ${color.grey};
    font-size: 1.6rem;
    position: absolute;
    bottom: 0;
    text-align: center;
  }
`