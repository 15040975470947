import React, { useEffect, useRef } from 'react'
import Img from "gatsby-image"
import styled from 'styled-components'
import { device } from "../../layout/global"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const FeatureLine = ({ id, info, marginLeft, positionCenter, whiteBg, alignLeft, fullHeight, ko}) => {

  const ref = useRef(null),
        imageRef = useRef(null)


  // ANIMATIONS
  useEffect(() => {
      gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: ref.current,
          start: 'top 70%'
        }
      })
      .fromTo(imageRef.current, {opacity: 0}, {opacity: 1, duration: 2, ease: "power4.out"})
     
  },[])
  // ---

  return (
    <Wrapper fullHeight={fullHeight} marginLeft={marginLeft} positionCenter={positionCenter} id={id} ref={ref} alignLeft={alignLeft} whiteBg={whiteBg} ko={ko}>
      {info.image &&
        <div className="image" ref={imageRef}>
          <Img
            fluid={info.image.childImageSharp.fluid}
            alt={info.image.name}
            imgStyle={{ objectFit: "contain", objectPosition: "center center" }}
          />
        </div>
      }
    </Wrapper>
  )
}

export default FeatureLine

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: ${props => props.fullHeight ? '100vh' : '500px'};
  overflow: hidden;
  background: ${props => props.whiteBg ? '#fff' : '#000'};

  @media ${device.tabletP}{
    height:600px;
  }

  .image{
    position: absolute;
    bottom: 0%;
    right: ${props => !props.fullHeight && '0'};
    left: ${props => props.fullHeight && '0'};
    width: 100vw;
    height: ${props => props.fullHeight && '100%'};

    @media ${device.tabletP}{
      width: ${props => props.fullHeight ? '100vw' : '60vw'};
    }

    .gatsby-image-wrapper{
      width:100%;
      height:100%;

      img{
        object-fit: ${props => props.fullHeight ? 'cover !important' : 'contain'};
      }
    }
  }
`