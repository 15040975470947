import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Link } from "gatsby"

import { font, color, ease, device } from "../../layout/global"

import LogoR from "../../images/Footer/logo_r.svg"
import LogoSmall from "../../images/Header/small-logo.svg"
import LogoFacebook from "../../images/Footer/logo_facebook.svg"
import LogoInstagram from "../../images/Footer/logo_instagram.svg"
import LogoLinkedIn from "../../images/Footer/logo_linkedin.svg"
import LogoYoutube from "../../images/Footer/logo_youtube.svg"

const Footer = ({ text1, text2, lang }) => {
  const botaoRef = useRef(null)
  const grupoRef = useRef(null)
  const d1Ref = useRef(null)
  const d2Ref = useRef(null)
  const d3Ref = useRef(null)
  const d4Ref = useRef(null)

  useEffect (() => {
    let btnRef = botaoRef.current
    let grpRef = grupoRef.current
    let d1 = d1Ref.current
    let d2 = d2Ref.current
    let d3 = d3Ref.current
    let d4 = d4Ref.current

    btnRef.addEventListener('click', function(){
      if (grpRef.style.display === 'block'){
        setTimeout(function(){
          grpRef.style.display = 'none'
         }, 200);
        
        setTimeout(function(){ 
          d1.classList.remove('active')
          d2.classList.remove('active')
          d3.classList.remove('active')
          d4.classList.remove('active')
        }, 50);
        
      } else{
        grpRef.style.display = 'block' 
        setTimeout(function(){ 
          d1.classList.add('active')
          d2.classList.add('active')
          d3.classList.add('active')
          d4.classList.add('active')
        }, 50);
      }
    })
  }, [])

  const breakpoints = useBreakpoint();

  return(
    <Wrapper>
      <div className='footer-container'>
        {/* <div></div> */}
        <div>
          {breakpoints.md ? 
            <LogoSmall className="logo"/> : 
            <LogoR className="logo"/>} 
        </div>
        <div className="info-primary">
          <div className="links">
            <a href="tel:+351234630200" rel='noreferrer noopener'>
              <p>+351 234 630 200</p>
            </a>
            <a href="mailto:ramalhos@ramalhos.com" rel='noreferrer noopener'>
              <p>ramalhos@ramalhos.com</p>
            </a>
          </div>
          <div className="address">
            <a href="https://www.google.pt/maps/place/Ramalhos/@40.6009722,-8.4460999,17z/data=!3m1!4b1!4m5!3m4!1s0xd230a53bf380dbd:0x7829f31817268bb0!8m2!3d40.6009681!4d-8.4439112?hl=pt-PT" rel='noreferrer noopener' target='_blank'>
              <p>Rua Arrota de Baixo, 823</p>
              <p>Covão ZI EN1 Norte</p>
              <p>3750-802 Águeda-Portugal</p>
            </a>
          </div>
        </div>
        {/* <div></div> */}
        <div className="info-secondary">
          <div className="links">
            <Link to={`/${lang}/aftersales`}><p>{text1}</p></Link>
            <div className="p-list">
              <button type="button" className="btn-empresarial" value="btn-empresarial" ref={botaoRef}>
                {text2}
              </button>
              <ul className='grupo-empresarial' ref={grupoRef}>
                <li className="d1" ref={d1Ref}><a href="https://www.exporlux.pt/en/" rel='noreferrer noopener' target='_blank'>Exporlux</a></li>
                <li className="d2" ref={d3Ref}><a href="https://www.soneres.pt/pt/" rel='noreferrer noopener' target='_blank'>Soneres</a></li>
                <li className="d3" ref={d2Ref}><a href="http://www.lightdesign.com.br/index.php" rel='noreferrer noopener' target='_blank'>Light Design</a></li>
                <li className="d4" ref={d4Ref}><a href="http://www.soneres.com.br" rel='noreferrer noopener' target='_blank'>Soneres Brasil</a></li>
              </ul>
            </div>
          </div>
        </div>
        {/* <div></div>
        <div></div> */}
        <div className="social-links">
          <div>
            <a href="https://www.facebook.com/ramalhos.ovens/" rel='noreferrer noopener' target='_blank'><LogoFacebook/></a>
            <a href="https://www.instagram.com/ramalhos.ovens/" rel='noreferrer noopener' target='_blank'><LogoInstagram/></a>
            <a href="https://www.linkedin.com/company/ramalhos/" rel='noreferrer noopener' target='_blank'><LogoLinkedIn/></a>
            <a href="https://www.youtube.com/channel/UCbyt5AhYkuuglVvD1QKDhwg" rel='noreferrer noopener' target='_blank'><LogoYoutube/></a>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  width: 100%;
  color: ${color.white};
  background-color: ${color.red};
  z-index:2;

  .footer-container{
    max-width:2000px;
    margin:0 auto;
    
    @media ${device.mobileP}{
      display:grid;
      grid-template-areas:
          'logo logo'
          'first second'
          '. social';
      grid-row-gap:40px;
      padding: 35px 0; 
      width:90%;
    }

    @media ${device.tabletL}{
      display:flex;
      width: 90%;
      max-width: 900px;   
      justify-content: space-between;
    }

    @media ${device.desktop}{
      padding: 50px 0; 
    }
  }

  .logo {
    fill: ${color.white};
    grid-area:logo;
    width: 40px;
    height: auto;
  }

  p, .p-list {
    ${font.robotoMedium};
    font-size: 1.2rem;
    line-height:1.5;
  }

  .info-primary {
    display: grid;
    grid-template-rows: repeat(2, auto);
    grid-area:first;

    @media ${device.mobileP}{
      grid-row-gap:35px;
    }

    @media ${device.desktop}{
      grid-row-gap:none;
    }
  }
  .info-secondary{
    grid-area:second;
  }
  .info-primary .links {
    align-self: start;
  }
  .info-primary .address {
    align-self: end;
  }

  .social-links {
    display: grid;
    align-items: end;
    justify-self: left;
    grid-area:social;

    @media ${device.mobileP}{
      width:100%;
    }

    @media ${device.tabletL}{
      width:unset;
    }
  }

  .social-links div{
    @media ${device.mobileP}{
      display:flex;
      justify-content:flex-end;
    }
  }

  .social-links svg {
    fill: ${color.white};

    @media ${device.mobileP}{
      margin-right: 10px;
    }

    @media ${device.desktop}{
      margin-right: 15px;
    }
  }
  
  a{
    color:#fff;
    &:hover{
      text-decoration:underline;
    }
  }

  button{
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    color: inherit;
    font: inherit;
    line-height: normal;
    outline:0;
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;
    -webkit-appearance: none;

    &:hover{
      cursor:pointer;
      text-decoration:underline;
    }
  }

  .grupo-empresarial{
    display:none;
    margin-left:15px;
    color: #fff;
    transition: all 0.2s ${ease.out};
  }

  .grupo-empresarial:hover{
    color: rgba(255,255,255,0.596);
    transition: all 0.2s ${ease.out};
  }

  .d1, .d2, .d3, .d4{
    line-height:1.5;
  }

  .d1:hover, .d2:hover, .d3:hover, .d4:hover{
    color: #fff;
    transition: all 0.2s ${ease.out};
  }

  .d1{
    opacity:0;
    transition: opacity 0.6s;
  }

  .d2{
    opacity:0;
    transition: opacity 0.5s;
  }

  .d3{
    opacity:0;
    transition: opacity 0.4s;
  }

  .d4{
    opacity:0;
    transition: opacity 0.3s;
  }

  .d1.active{
    opacity:1;
    transition: opacity 0.3s;
  }
  .d2.active{
    opacity:1;
    transition: opacity 0.4s;
  }
  .d3.active{
    opacity:1;
    transition: opacity 0.5s;
  }
  .d4.active{
    opacity:1;
    transition: opacity 0.6s;
  }
`

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
