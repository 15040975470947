import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { font, color, ease, device } from "../../layout/global"
const TermsCheckbox = ({ hasTerms, hasShare, hasTermsNewsletter, language, checkmark, text1, text2, text3 }) => {

  let string = useRef(null)
  let string2 = useRef(null)
  let privacy = useRef(null)
  

  const terms01 = useRef(null)
  const terms02 = useRef(null)
  
  const [ focus, setFocus ] = useState(false)
  const [ focus2, setFocus2 ] = useState(false)
  
  useEffect(() => {
    switch(language) {
      case "pt":
        string.current = `É obrigatório aceitar a política de privacidade.`
        string2.current = `Campo obrigatório.`
        privacy.current = `privacy_policy_pt.pdf`
        break
      case "en":
        string.current = `You must accept the privacy policy.`
        string2.current = `Mandatory field.`
        privacy.current = `privacy_policy_en.pdf`
        break
      case "es":
        string.current = `Es obligatorio aceptar la política de privacidad.`
        string2.current = `Campo obligatorio.`
        privacy.current = `privacy_policy_es.pdf`
        break
      case "fr":
        string.current = `Il est obligatoire d'accepter la politique de confidentialité.`
        string2.current = `Champ obligatoire.`
        privacy.current = `privacy_policy_fr.pdf`
        break
      default:
        string.current = `É obrigatório aceitar a política de privacidade.`
        string2.current = `Campo obrigatório.`
        privacy.current = `privacy_policy_pt.pdf`
        break
    }
  }, [language])

  useEffect(() => {
    terms01.current.setCustomValidity(string.current)
    terms02.current.setCustomValidity(string2.current)
  }, [])

  useEffect(() => {
    if(focus) {
      terms01.current.checked ? terms01.current.setCustomValidity('') : terms01.current.setCustomValidity(string.current)
      setFocus(false)
    }
    if(focus2) {
      terms02.current.checked ? terms02.current.setCustomValidity('') : terms02.current.setCustomValidity(string2.current)
      setFocus2(false)
    }
  },[focus, focus2])

  const doFocus = () => {
    setFocus(true)
  }
  const doFocus2 = () => {
    setFocus2(true)
  }

  return (
    <Wrapper checkmark={checkmark}>
      {hasTerms &&
      <div className="terms-checkbox-wrapper">
        <input onChange={doFocus} ref={terms01} type="checkbox" id="terms-01" value="terms" name="terms" className="terms-checkbox" required/>
        <span className="terms-checkmark"/>
        <label htmlFor="terms-01"  id="terms-01-label" className="terms-label">{text1} <a alt={text2} target="_blank" rel="noopener noreferrer" href={privacy.current}>{text2}</a></label>
      </div>
      }
      {hasShare &&
      <div className="terms-checkbox-wrapper">
        <input onChange={doFocus2} ref={terms02} type="checkbox" id="terms-02" value="shareinfo" name="shareinfo" className="terms-checkbox" required/>
        <span className="terms-checkmark"/>
        <label htmlFor="terms-02" id="terms-02-label" className="terms-label">{text3}</label>
      </div>
      }
    </Wrapper>
  )
}

export default TermsCheckbox

const Wrapper = styled.div`

  .terms-checkbox-wrapper {
    position: relative;

    @media ${device.mobileP}{
      margin-top: 15px;
    }

    @media ${device.tabletP}{
      margin-top: 10px;
    }
  }

  .terms-checkbox-wrapper:first-child{
    @media ${device.tabletP}{
      margin-top: 10px;
    }
  }

  .terms-checkbox {
    opacity: 0;
    background: transparent;
    outline: 0;
    border: none;
  }
  .terms-checkmark {
    position: absolute;
    top: 0;
    left: 4px;
    height: 16px;
    width: 16px;
    background-color: transparent;
    pointer-events: none;
    transition: all 0.15s ${ease.out};

    &:after {
      position: absolute;
      display: block;
      content: "";
      left: 4px;
      top: 0px;
      width: 7px;
      height: 12px;
      border: solid ${color.grey};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      transition: all 0.15s ${ease.out};
      
    }
  }
  .terms-label {
    position: absolute;
    ${font.robotoMedium};
    letter-spacing: 0.2px;
    color: ${color.grey};
    font-size: 1.1rem;
    font-style: normal;
    transform: skew(-5deg);
    padding-left: 14px;
    transition: all 0.15s ${ease.out};
    user-select: none;

    &:hover{
      cursor: pointer;
    }
  }
  .terms-checkbox:checked ~ .terms-checkmark:after {
    border-color: ${color.red};
  }
  .terms-checkbox:checked ~ .terms-label {
    color: ${color.red};
  }
  .terms-checkbox:hover:not(:checked) ~ .terms-checkmark:after {
    border-color: ${color.greyDarker};
  }
  .terms-checkbox:hover:not(:checked) ~ .terms-label {
    color: ${color.greyDarker};
  }
`