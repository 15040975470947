import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { color, font, device } from '../../layout/global'
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger)
  gsap.core.globals("ScrollTrigger", ScrollTrigger)
}

const SlidingText = ({ id, info, ko }) => {
  const breakpoints = useBreakpoint()

  const wrapperRef = useRef(null), textLeftRef = useRef(null), textRightRef = useRef(null)
  
  useEffect(() => {
    gsap.timeline({
      paused: true,
      scrollTrigger: {
        trigger: wrapperRef.current,
        start: '0% center',
        end: '35% center',
        scrub: 1.5
      }
    })
    .fromTo(textLeftRef.current, {x: '-50vw', opacity: 0}, {x: '0', opacity: 1, duration: 2, ease: 'none'})
    .fromTo(textRightRef.current, {x: '50vw', opacity: 0}, {x: '0', opacity: 1, duration: 2, ease: 'none'}, '<')
  }, [])

  return (
    <Wrapper id={id} ref={wrapperRef} ko={ko}>
      <div id="text-from-left" className="text-container" ref={textLeftRef}>
        <div className="text-center">{info.text_1_1}</div>
          {!breakpoints.as &&
            <>
              <div>{info.text_1_2}</div>
              <div>{info.text_1_3}</div>
            </>
          }
          {breakpoints.as &&
           <div>{info.text_1_2}<br/>{info.text_1_3}</div>
          }
        </div>
      
        <div id="text-from-right" className="text-container" ref={textRightRef}>
          {!breakpoints.as &&
            <>
              <div>{info.text_2_1}</div>
              <div>{info.text_2_2}</div>
            </>
          }
          {breakpoints.as &&
            <div>{info.text_2_1}<br/>{info.text_2_2}</div>
          }
        </div>
    </Wrapper>
  )
}

export default SlidingText

const Wrapper = styled.section`
  width: 100%;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  overflow: hidden;
  padding: 60px 25px;

  @media ${device.mobileP} {
    padding: 100px 5px;
  }
  @media ${device.tabletP}{
    padding: 180px 20px;
  }

  @media ${device.desktop}{
    padding: 0 5vw;
    height: 100vh;
  }

  .text-center {
    text-align:left;
    margin-bottom:15px;

    @media ${device.desktop}{
      text-align: ${props => props.ko ? 'left' : 'center'}; 
      margin: unset;
    }
  }

  .text-container {
    height: fit-content;
    width: 100%;

    @media ${device.desktop}{
      width: ${props => props.ko ? '90vw' : '100%'};
    }
  }

  .text-container div {
    ${props => props.ko ? font.Noto400 : font.financierLight};
    color: ${color.whiteOpacity};
    font-size: ${props => props.ko ? '4rem' : '5rem'};
    text-align: left;
    line-height: normal;
    width: 100%;

    @media ${device.desktop}{
      font-size: ${props => props.ko ? '8rem' : '10rem'};
    }
  }

  @media screen and (min-width: 320px) and (max-width: 1920px) {
    .text-container div {
      font-size: ${props => props.ko ? 'calc(14px + 52 * (100vw - 320px) / 1600)' : 'calc(40px + 60 * (100vw - 320px) / 1600)'};
    }
  }

  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
    justify-content: left;
    padding: 0 10vw;

    .text-container {
      width: fit-content;
    }
  }
`